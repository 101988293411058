import { openPopup } from '../helpers/popup-helper';
import { readCookie } from './../helpers/cookies-helper';
const shareTriggerClass = 'js-share-page';
const popupId = 'js-share-page-popup';
const shareButtonClass = 'js-share-button';

async function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;

        textArea.style.position = 'absolute';
        textArea.style.left = '-999999px';

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}

function setSharePage() {
    document.addEventListener('click', function (e) {
        if (e.target.matches('.' + shareTriggerClass)) {
            e.preventDefault();
    
            const popup = document.getElementById(popupId);
            let target = e.target.href;
            target = target.replace(/\/#$/, '');
    
            let cookie = readCookie('lokum_fav');
            if (popup) {
                document.querySelector('.' + shareButtonClass).dataset.url =
                    target + '?show=' + cookie;
                openPopup(popup);
            }
        }
    });
    
    const mailShareClass = 'js-share-in-email';
    document.addEventListener('click', function (e) {
        if (e.target.matches('.' + mailShareClass)) {
            let url = e.target.dataset.url;
            let description = e.target.dataset.description;
    
            window.open('mailto:?subject=' + description + '&body=' + url);
        }
    });
    
    const copyShareClass = 'js-share-in-copy';
    const copyShareEl = document.querySelectorAll(`.${copyShareClass}`);

    copyShareEl.forEach(item => {
        item.addEventListener('click', async (e) => {
            const { currentTarget } = e;
            const { previousElementSibling } = currentTarget;
            const copiedText = currentTarget.getAttribute('data-copied-text');
            const baseText = currentTarget.getAttribute('data-base-text');
            const url = currentTarget.getAttribute('data-url');
    
            await copyToClipboard(url);
            currentTarget.classList.add('is-copied');
            if (previousElementSibling) previousElementSibling.innerHTML = copiedText;

            document.addEventListener('popupClosed', function () {
                currentTarget.classList.remove('is-copied');
                if (previousElementSibling) previousElementSibling.innerHTML = baseText;
            });
        })
    })
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    setSharePage();
} else {
    document.addEventListener('DOMContentLoaded', setSharePage);
}